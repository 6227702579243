import React, { useEffect, useState } from 'react';
import { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { Environment, OrbitControls, PerspectiveCamera } from '@react-three/drei';
import Plate from './Plate';
import Loader from './Loader';
import ModelStatue from './ModelStatue';
import ModelAsset from './ModelAsset';

export default function Scene({ number, revealToNumber, revealed, plateColor, animation }) {
  const [currentSpecies, setCurrentSpecies] = useState([])

  useEffect(() => {
    const species = ['male-bg', 'female-bg', 'rare-bg', 'legendary-bg']

    if(plateColor === 'Male'){
      setCurrentSpecies(species[0])
    } else if (plateColor === 'Female') {
      setCurrentSpecies(species[1])
    } else if (plateColor === 'Legendary') {
      setCurrentSpecies(species[3])
    } else if (plateColor === 'Robot' || 'Minibot' || 'Megabot' || 'Alien') {
      setCurrentSpecies(species[2])
    } 
  }, [plateColor])

  
  return (
    <div>
      <div className={`${revealed && number <= revealToNumber ? `viewer-revealed ${currentSpecies}` : 'viewer'}`}>
        <Suspense fallback={<Loader />}>
          <Canvas shadows>
            <PerspectiveCamera position={[0, 0, 6]} fov={50} makeDefault />
            <spotLight
              position={[10, 34, 14]}
              angle={[10]}
              intensity={0.5}
              castShadow
              shadow-mapSize-width={2048}
              shadow-mapSize-height={2048}
              shadow-camera-left={-10}
              shadow-camera-right={10}
              shadow-camera-top={10}
              shadow-camera-bottom={-10}
              shadow-camera-near={0.1}
              shadow-camera-far={100}
            />
            {revealed && number <= revealToNumber && number !== '' ? (
              <ModelAsset number={number} animation={animation} />
            ) : (
              <ModelStatue animation={animation} />
            )}
            <Environment files="images/modern2.hdr">
            </Environment>
            <Plate />
            <OrbitControls 
                minPolarAngle={Math.PI / 2.4}
                maxPolarAngle={Math.PI / 2}
                minDistance={4}
                maxDistance={12}
                enablePan={false}
            />
          </Canvas>
        </Suspense>
      </div>
    </div>
  );
}