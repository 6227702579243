import './App.css';
import ImageComponent from './components/ImageComponent';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  const revealed = true;
  return (
    <Router>
    <div className="App">
      <Routes>
          <Route path="/:number" element={<ImageComponent revealed={revealed} />} />
          <Route path="/" element={<ImageComponent revealed={revealed} />} />
      </Routes>
    </div>
    </Router>
  );
}

export default App;
