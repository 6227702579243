import { useEffect, useRef } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useLoader } from '@react-three/fiber';
import { useAnimations } from '@react-three/drei';

const ModelAsset = ({ number, animation }) => {
  const currentAction = useRef('');
  const gltf = useLoader(GLTFLoader, `/vx/${number}.gltf`);
  const { actions } = useAnimations(gltf.animations, gltf.scene);

  useEffect(() => {
    if (currentAction.current !== animation) {
      const nextActionToPlay = actions[animation];
      const current = actions[currentAction.current];
      current?.fadeOut(0.2);
      
      nextActionToPlay?.reset().fadeIn(0.2).play();
      currentAction.current = animation;
    }
  }, [animation, actions]);

  useEffect(() => {
    gltf.scene.traverse((object) => {
      if (object.isMesh) {
        object.castShadow = true;
        object.receiveShadow = true;
      }
    });
  }, [gltf]);

  return (
    <primitive
      object={gltf.scene}
      scale={0.04}
      position={[0, -1, 0]}
      rotation={[0, 3.5, 0]}
    />
  );
};

export default ModelAsset;
