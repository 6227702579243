import React from 'react'

function Plate() {
  return (
  <>
    <mesh receiveShadow position={[0,-1.0,0]} rotation={[Math.PI / -2, 0, 0]} >
    <planeGeometry args={[ 100, 100, 100 ]} />
      <shadowMaterial opacity={0.5} />
    </mesh>

  </>
  )
}

export default Plate