import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scene from "./Scene";

const ImageComponent = ({ revealed }) => {
  const revealToNumber = 8830;
  const [animation, setAnimation] = useState("Idle 01");
  const [open, setOpen] = React.useState(false);

  const [plateColor, setPlateColor] = useState("");
  
  // useLocation instead of useParams
  const location = useLocation();
  let number = location.hash.substring(1);
  if(!number) {
    number = 11000
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}${
            revealed && number <= revealToNumber
              ? `/data/${number - 1}.json`
              : `/data/unrevealed.json`
          }`
        );
        const data = await response.json();
        setPlateColor(data?.attributes[0]?.value);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchData();
  }, [number, revealed]);


  const handleOpen = () => {
    setOpen(!open);
  };
  const handleMenuOne = () => {
    setAnimation("Mad 01");
    setOpen(false);
  };

  const handleMenuTwo = () => {
    setAnimation("Walk 01");
    setOpen(false);
  };
  const handleMenuThree = () => {
    setAnimation("Idle 01");
    setOpen(false);
  };
  const handleMenuFour = () => {
    setAnimation("Dance 01");
    setOpen(false);
  };
  const handleMenuFive = () => {
    setAnimation("Run 01");
    setOpen(false);
  };

  
  return (
    <div>
      <Scene
        number={number}
        revealToNumber={revealToNumber}
        revealed={revealed}
        plateColor={plateColor}
        animation={animation}
      />
      <div className="changer-2 font-bold" onClick={handleOpen}>
        <div className="hover:scale-105">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
          width="32px"
          height="32px"
          viewBox="0 0 24 24"
        >
          <g data-name="Horizontal Setting">
            <path d="M2 5h1.18a3 3 0 0 0 5.64 0H22a1 1 0 0 0 0-2H8.82a3 3 0 0 0-5.64 0H2a1 1 0 0 0 0 2zm4-2a1 1 0 0 1 0 2 1 1 0 0 1 0-2zM21.82 11a3 3 0 0 0-5.64 0H2a1 1 0 0 0 0 2h14.18a3 3 0 0 0 5.64 0 1 1 0 1 0 0-2zM19 13a1 1 0 0 1 0-2 1 1 0 0 1 0 2zM22 19h-7.18a3 3 0 0 0-5.64 0H2a1 1 0 0 0 0 2h7.18a3 3 0 0 0 5.64 0H22a1 1 0 0 0 0-2zm-10 2a1 1 0 0 1 0-2 1 1 0 0 1 0 2z" />
          </g>
        </svg>
        </div>
        {open ? (
          <ul className="menu">
            <li className="menu-item">
              <button onClick={handleMenuOne}>MAD</button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuTwo}>WALK</button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuThree}>IDLE</button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuFour}>DANCE</button>
            </li>
            <li className="menu-item">
              <button onClick={handleMenuFive}>RUN</button>
            </li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default ImageComponent;
